import { Post } from 'src/app/models/post.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterPosts'
})
export class FilterPostsPipe implements PipeTransform {

    transform(posts: Post[], searchWord: string): Post[] {

        if (!searchWord)
            return posts;

        return posts.filter(
            m =>
                m.receiver?.name?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.destination?.address?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.cdekNumber?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.spi?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.sender?.address?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.sender?.name?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.id?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0 ||
                m.status?.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0
        );
    }

}
