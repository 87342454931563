import { Post } from 'src/app/models/post.model';
import { AllInfo } from './../models/all-info.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginReq } from '../models/login.model';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiUrl = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router
    ) { }

    public readonly info = ((api: this) => ({
        getAll(): Promise<AllInfo> {
            return api.apiCallGet("GetAdminInfo");
        },
        getPost(id: string): Promise<Post> {
            return api.apiCallGet("GetAdminPost", { id });
        },
        savePost(id: string, officeId: string): Promise<Post> {
            return api.apiCallGet("UpdatePost", { id: id, officeId: officeId });
        }
    }))(this);

    public readonly accounts = ((api: this) => ({
        login(req: LoginReq): Promise<string> {
            return api.apiCall(`AdminLogin`, req);
        },
    }))(this);

    public readonly pdf = ((api: this) => ({
        /* Квитанция о вручении (Статус: completed) */
        postReceiveFormPdf(postId: string, openInBrowser: boolean = true) {
            // return api.apiCallGet<string>("PostReceiveFormPdf", {postId: postId, openInBrowser: openInBrowser});
            return api.apiUrl + `PostReceiveFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Квитанция об отправлении (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postSendFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostSendFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Опись отправления (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postIndexFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostIndexFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Квитанция об оплате (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postPaymentFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostPaymentFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Конверт */
        postCoverPrint(postId: string, width: number, height: number) {
            return api.apiUrl + `PostCoverPrint?postId=${postId}&width=${width}&height=${height}`;
        },
    }))(this);

    //#region private methods

    private apiCall(url: string, params: any): Promise<any> {
        return this.http
            .post(this.apiUrl + url, params, this.makeOptions())
            .toPromise()
            .catch((e) => {
                if (e.status == '401' || e.status == '0')
                    this.router.navigateByUrl('/login');
                this.toastr.error(e.error.message)
            });
    }

    private apiCallGet(url: string, params?: any): Promise<any> {
        let options = this.makeOptions() as any;

        if (params) {
            options.params = params;
        }

        return this.http
            .get(this.apiUrl + url, options)
            .toPromise()
            .catch((e) => {
                if (e.status == '401' || e.status == '0')
                    this.router.navigateByUrl('/login');
                this.toastr.error(e.error.message)
            });
    }

    makeOptions() {
        let headers: HttpHeaders = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const token = localStorage.getItem('auth');

        if (token)
            headers = headers.set('auth', token);
        return { headers: headers };
    }

}

export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}
//#endregion
