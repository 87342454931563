import { ApiService } from './../../services/api.service';
import { Post } from 'src/app/models/post.model';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import {Office} from "../../models/office.model";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
    post: Post;
    offices: Office[];

    constructor(private route: ActivatedRoute,
                private api: ApiService,
                private toastr: ToastrService) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const postId = params['id'];
            if (!postId) return;

            this.api.info.getPost(postId).then(post => {
                this.post = post;
            });

            this.api.info.getAll().then(data => {
                this.offices = data.offices;
            });
        });
    }


    isReceivePdf(status: string) {
        return status == "completed" ? true : false;
    }

    isSendPdf(status: string) {
        return status != "new" && status != "waitingPayment" ? true : false;
    }

    isIndexPdf(status: string) {
        return status != "new" && status != "waitingPayment" ? true : false;
    }

    isNew(status: string) {
        return status == "new" || status == "waitingPayment" ? true : false;
    }

    genPaymentPdf(postId) {
        window.open(this.api.pdf.postPaymentFormPdf(postId));
    }

    genReceivePdf(postId) {
        window.open(this.api.pdf.postReceiveFormPdf(postId));
    }

    genSendPdf(postId) {
        window.open(this.api.pdf.postSendFormPdf(postId));
    }

    genIndexPdf(postId) {
        window.open(this.api.pdf.postIndexFormPdf(postId));
    }

    genCoverPrint(postId, width, height) {
        window.open(this.api.pdf.postCoverPrint(postId, width, height), '_blank');
    }

    async save(): Promise<any> {
        if (this.post.officeId) {
            const newPost = await this.api.info.savePost(this.post.id, this.post.officeId);
            if (newPost)
                this.toastr.success('Офис обслуживания сохранен');
        }
    }
}
