<app-header></app-header>
<div class="container mt-4" *ngIf="info && info.posts && info.archivedPosts && info.offices && info.customers">

    <div class="row">
        <div class="col-sm-6">
            <h2 class="mb-4">Статистика</h2>
        </div>
        <div class="col-sm-6">
            <button class="btn btn-success float-end" (click)="export()">
                <span>Выгрузить реестр с {{dateStart | date:"dd.MM.yyyy"}} по {{dateEnd | date:"dd.MM.yyyy"}}</span>
            </button>
        </div>
    </div>

    <table class="table" style="max-width: 300px;">
        <tr>
            <td>Количество пользователей:</td>
            <th>{{numberOfUsers}}</th>
        </tr>

    </table>

    <div class="statistics">
        <div class="statistics__date-actions">
            <div class="input-group">
                <input type="date" [ngModel]="dateStart | date:'yyyy-MM-dd'" (ngModelChange)="dateStart = $event"
                    class="form-control">
                <input type="date" [ngModel]="dateEnd | date:'yyyy-MM-dd'" (ngModelChange)="dateEnd = $event">
                <button class="btn btn-success" (click)="filterDate()">Применить</button>
            </div>
        </div>

        <div class="statistics__posts-info">
            <div *ngIf="posts" class="statistics__posts-info--statuses">
                <h4 class="mb-4">Отправления <strong>({{posts.length}})</strong></h4>
                <p *ngFor="let r of labels; index as i">
                    <small [style.color]="colors[i]">{{counts[i]}}</small>
                    <span> {{r |statusPost}}</span>
                </p>
            </div>
            <div class="statistics__posts-info--graph">
                <canvas id="myChart"></canvas>
            </div>
        </div>

        <table class="table mb-5" style="max-width: 300px;">
            <tr>
                <td>Общее количество отправлений:</td>
                <th>{{totalNumberOfApplications}}</th>
            </tr>
            <tr>
                <td>На обработке:</td>
                <th>{{processedNumber}}</th>
            </tr>
            <tr>
                <td>Обработано:</td>
                <th>{{onProcessingNumber}}</th>
            </tr>
        </table>
    </div>

    <div class="post-list">
        <div class="post-list__search">
            <h4>Отправления с {{dateStart | date:"dd.MM.yyyy"}} по {{dateEnd | date:"dd.MM.yyyy"}}</h4>
            <input #search [(ngModel)]="searchWord" class="form-control mb-2" placeholder="Поиск" type="search">
        </div>
        <div class="post-list__table">
            <table class="table table-bordered  table-hover table-sm">
                <thead>
                    <tr>
                        <th scope="col">Отправитель</th>
                        <th scope="col">Получатель</th>
                        <th scope="col">Номер</th>
                        <th scope="col">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="createdAt" [class.desc]="isSortSettings.createdAt.sorting" [class.active]="isSortSettings.createdAt.active">Дата</span>
                        </th>
                        <th scope="col">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="postalServiceAlias" [class.desc]="isSortSettings.postalServiceAlias.sorting" [class.active]="isSortSettings.postalServiceAlias.active">Почтовая служба</span>
                        </th>
                        <th scope="col">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="widthPageAtt" [class.desc]="isSortSettings.widthPageAtt.sorting" [class.active]="isSortSettings.widthPageAtt.active">Кол-во листов/Вес</span>
                        </th>
                        <th scope="col">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="status" [class.desc]="isSortSettings.status.sorting" [class.active]="isSortSettings.status.active">Статус</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let post of posts | filterPosts:searchWord" [routerLink]="['post', post.id]" [class.problem]="!post.officeId">
                        <td style="max-width: 300px;">
                            <p>{{post.sender.name}}</p>
                            <p>{{post.sender.phone}}</p>
                            <p *ngIf="post.sender.address != 'null, null, null'">{{post.sender.address != 'null, null, null' ? post.sender.address : null}}</p>
                        </td>
                        <td>
                            <p>{{post.receiver.name}}</p>
                            <p>{{post.receiver.phone}}</p>
                            <p>{{post.destination.address}}</p>
                        </td>
                        <td style="max-width: 300px;">
                            <p *ngIf="post.postalServiceAlias == 0">{{post.cdekNumber || post.id}}</p>
                            <p *ngIf="post.postalServiceAlias == 1">{{post.spi || post.id}}</p>
                        </td>
                        <td>
                            <p>{{post.createdAt | date:"dd.MM.yyyy hh:mm"}}</p>
                        </td>
                        <td>
                            <p style="text-align: center;" [innerHTML]="post.postalServiceAlias == 0 ? 'CDEK' : post.postalServiceAlias == 1 ? 'НПС' : null"></p>
                            <p style="text-align: center;">{{officeName(post)}}</p>
                        </td>
                        <td>
                            <p>Кол-во листов: {{countPageAtt(post)}}</p>
                            <p>Вес: {{widthPageAtt(post)}}г.</p>
                        </td>
                        <td>
                            <p>{{post.status | statusPost}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="problem-label">- не указан офис обслуживания</p>
            <button class="btn btn-success float-end" (click)="export()">
                <span>Выгрузить реестр с {{dateStart | date:"dd.MM.yyyy"}} по {{dateEnd | date:"dd.MM.yyyy"}}</span>
            </button>
        </div>
    </div>
</div>
