import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginReq } from 'src/app/models/login.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        let login = this.loginForm.controls['login'].value;
        let password = this.loginForm.controls['password'].value;

        if (this.loginForm.invalid) {
            return;
        }

        const loginReq: LoginReq = {
            login: this.loginForm.controls['login'].value,
            pwd: this.loginForm.controls['password'].value
        }

        this.apiService.accounts.login(loginReq)
            .then(res => {
                if (res) {
                    localStorage.setItem("auth", res);
                    this.router.navigateByUrl('/');
                }
            })
    }

}
