import { ApiService } from './services/api.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { StatusPostPipe } from './pipes/status-post.pipe';
import { StatusPostReversePipe } from './pipes/status-post-reverse.pipe';
import { FilterPostsPipe } from './pipes/filter-posts.pipe';
import { PostComponent } from './components/post/post.component';
import { LoginComponent } from './components/login/login.component';
import {CsvService} from './services/csv.service';
import { DropdownDirective } from './directives/dropdown.directive';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    StatusPostPipe,
    StatusPostReversePipe,
    FilterPostsPipe,
    PostComponent,
    LoginComponent,
    DropdownDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [CsvService],
  bootstrap: [AppComponent]
})
export class AppModule { }
