<div class="container">
    <div class="login">
        <div class="login-block">
            <h1 class="login__title h1">
                Электронная почта
            </h1>
            <div class="login__subtitle">
                Панель администратора
            </div>
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <label class="login-form__label form-label" for="login">Введите логин</label>
                <input class="login-form__input form-control mr-3" type="text" id="login" formControlName="login"
                    [ngClass]="{ 'is-invalid': submitted && f.login.errors }">

                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">
                        Введите логин
                    </div>
                </div>

                <label class="login-form__label form-label mt-3" for="password">Введите пароль</label>
                <input class="login-form__input form-control mr-3" type="password" id="password"
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.login.errors }">

                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Введите пароль</div>
                </div>

                <div class="d-flex justify-content-end">
                    <button class="login-form__button btn btn-primary mt-3 btn-success">
                        <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
