import { Office } from './../../models/office.model';
import { Customer } from './../../models/customer.model';
import { ApiService } from './../../services/api.service';
import { AllInfo } from './../../models/all-info.model';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Post } from 'src/app/models/post.model';
import { StatusPostPipe } from '../../pipes/status-post.pipe';
import { StatusPostReversePipe } from 'src/app/pipes/status-post-reverse.pipe';
import {CsvService} from '../../services/csv.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    info: AllInfo;

    posts: Post[];
    customers: Customer[];
    offices: Office[];

    filterPosts: Post[];
    searchWord: string;

    dateStart = new Date().setDate(new Date().getDate() - 30);
    dateEnd = new Date();

    numberOfUsers: number; // Количество пользователей
    totalNumberOfApplications: number; // Общее количество заявок
    onProcessingNumber: number;
    processedNumber: number;

    isSortSettings = {
        createdAt: { active: false, sorting: false },
        postalServiceAlias:  { active: false, sorting: false },
        widthPageAtt:  { active: false, sorting: false },
        status:  { active: false, sorting: false }
    };

    constructor(
        private api: ApiService,
        private statusPipeReverse: StatusPostReversePipe,
        private csvService: CsvService
    ) { }

    ngOnInit() {
        this.getInfo();
    }

    public export(): any {
        const exportA: any[] = [];
        const data = this.posts.filter(x => x.status !== 'new');
        data.forEach((m) => {
            const e: any =  {
                Индекс: m.receiver.postalCodeIndex.toString(),
                Получатель: m.receiver.name.toString(),
                Адрес: m.destination.address.toString(),
                ШПИ: m.spi.toString(),
                Уин: '',
                Комментарий: `Отправитель: ${m.sender.name}, ${m.sender.address !== 'null, null, null' ? m.sender.address + ', ' : null} тел.: ${m.sender.phone}`,
                Телефон: m.receiver.phone.toString(),
                email: '',
                'признак юр лица 1- юр о - физ': (m.receiver.name === null ? '0' : '1')
            };
            exportA.push(e);
        });

        this.csvService.downloadFile(exportA, `Реестр`);
    }

    async getInfo() {
        const res = await this.api.info.getAll();

        console.log(res);
        if (!res)
            return;

        this.info = res;
        this.filterDate();
    }

    filterDate() {
        this.posts = (this.info.posts.concat(this.info.archivedPosts))
            .filter(p =>
                new Date(p.createdAt).valueOf() > new Date(this.dateStart).valueOf() &&
                new Date(p.createdAt).valueOf() < new Date(this.dateEnd).valueOf());

        this.customers = this.info.customers;
        this.offices = this.info.offices;
        this.count(this.info);
        this.graphInit();
    }

    count(info: AllInfo) {
        this.numberOfUsers = this.customers.length;
        this.totalNumberOfApplications = this.posts.length;
        this.processedNumber = this.posts.filter(p => p.isPrinted).length;
        this.onProcessingNumber = this.posts.filter(p => !p.isPrinted).length;
    }

    graphInit() {
        setTimeout(() => this.graphStart());
    }

    labels: string[] = [
        'Создано',
        'Заполнено',
        'Оплачено',
        // 'Распечатано',
        'В доставке',
        'Оставлено извещение',
        'Доставлено',
        'Отменено',
        'Не доставлено',
    ];

    colors = [
        '#219653',
        '#F2D94C',
        '#27AE60',
        '#6FCF97',
        '#EB5757',
        '#D33F3F',
        '#F2914A',
        '#dc3545',
    ];
    counts: number[] = [];

    graphStart() {
        this.counts = []

        for (let i = 0; i < this.labels.length; i++) {
            this.counts.push(0);
        }
        this.posts.forEach(r => {
            this.labels.forEach((l, i) => {
                if (this.statusPipeReverse.transform(l) == r.status)
                    this.counts[i]++;
            });
        });


        try {
            // @ts-ignore
            let canv: HTMLCanvasElement = document.getElementById('myChart');
            let ctx = canv.getContext('2d');

            let data = [
                71,
                46,
                34,
                67,
                9,
                58,
                42,
                113,
                16,
                68,
                43
            ];
            data = this.counts;

            let chart = new Chart(ctx, {
                // The type of chart we want to create
                type: 'doughnut',

                // The data for our dataset
                data: {
                    labels: this.labels,
                    datasets: [{
                        label: 'My First dataset',
                        backgroundColor: this.colors,
                        data: data,
                        borderWidth: 0
                    }]
                },

                // Configuration options go here
                options: {
                    cutoutPercentage: 60,
                    legend: {
                        display: false
                    }
                }
            });
        } catch (er) {
            console.warn(er.message);
        }
    }

    countPageAtt(post: Post): any {
        let r = 0;
        post?.attachments?.forEach(a => {
            r = r + a.pageCount;
        });
        return r;
    }

    widthPageAtt(post: Post): any {
        let r = 0;
        post?.attachments?.forEach(a => {
            r = r + a.pageCount;
        });
        r = r * 5;
        post.widthPageAtt = r;
        return r;
    }

    officeName(post: Post): any {
        return this.info.offices.filter(x => x.id === post.officeId)[0]?.name;
    }

    sortPosts(el: HTMLElement): any {
        const name = el.getAttribute('name');

        this.isSortSettings.createdAt.active = false;
        this.isSortSettings.postalServiceAlias.active = false;
        this.isSortSettings.widthPageAtt.active = false;
        this.isSortSettings.status.active = false;

        switch (el.getAttribute('name')) {
            case 'createdAt': {
                this.isSortSettings.createdAt.active = true;
                if (this.isSortSettings.createdAt.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) || [];
                    this.isSortSettings.createdAt.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)) || [];
                    this.isSortSettings.createdAt.sorting = true;
                }
                break;
            }
            case 'status': {
                this.isSortSettings.status.active = true;
                if (this.isSortSettings.status.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.status < b.status ? 1 : -1)) || [];
                    this.isSortSettings.status.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.status > b.status ? 1 : -1)) || [];
                    this.isSortSettings.status.sorting = true;
                }
                break;
            }
            case 'postalServiceAlias': {
                this.isSortSettings.postalServiceAlias.active = true;
                if (this.isSortSettings.postalServiceAlias.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.postalServiceAlias < b.postalServiceAlias ? 1 : -1)) || [];
                    this.isSortSettings.postalServiceAlias.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.postalServiceAlias > b.postalServiceAlias ? 1 : -1)) || [];
                    this.isSortSettings.postalServiceAlias.sorting = true;
                }
                break;
            }
            case 'widthPageAtt': {
                this.isSortSettings.widthPageAtt.active = true;
                if (this.isSortSettings.widthPageAtt.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.widthPageAtt < b.widthPageAtt ? 1 : -1)) || [];
                    this.isSortSettings.widthPageAtt.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.widthPageAtt > b.widthPageAtt ? 1 : -1)) || [];
                    this.isSortSettings.widthPageAtt.sorting = true;
                }
                break;
            }
        }
    }

}
