import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
@Pipe({
    name: 'statusPost'
})
export class StatusPostPipe implements PipeTransform {

    transform(status: string) {
        switch (status) {
            case "new": return "Создано";
            case "waitingPayment": return "Заполнено";
            case "waitingOfficeActions": return "Оплачено";
            case "waitingForCourier": return "Распечатано";
            case "waitingDelivery": return "В доставке";
            case "completed": return "Доставлено";
            case "canceled": return "Отменено";
            case "noticeLeft" : return "Оставлено извещение";
            case "notSent" : return "Не доставлено";
        }
        return status;
    }

}
