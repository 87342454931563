<app-header></app-header>
<div class="container mt-4">
    <a class="link" [routerLink]="['/']">Вернуться</a>
    <div class="post mt-5" *ngIf="post">
        <h2>Информация об отправлении</h2>
        <a target="_blank" [href]="'https://courier.cdekpost.ru/shipment-archive/' + post.id">История отправления</a>
        <table class="table mt-5" style="max-width:500px;">
            <tr>
                <td>Кому:</td>
                <th>{{post.receiver.name}}</th>
            </tr>
            <tr>
                <td>Куда:</td>
                <th>{{post.destination.address}}</th>
            </tr>
            <tr>
                <td>Номер:</td>
                <th>{{post.cdekNumber || post.id}}</th>
            </tr>
            <tr>
                <td>Дата:</td>
                <th>{{post.createdAt | date:"dd.MM.yyyy hh:mm"}}</th>
            </tr>
            <tr>
                <td>Статус:</td>
                <th>{{post.status | statusPost}}</th>
            </tr>
            <tr *ngIf="offices?.length > 0 && post.postalServiceAlias == '1'">
                <td>Офис обслуживания:</td>
                <th>
                    <div class="input-group">
                        <select class="form-select" [(ngModel)]="post.officeId">
                            <option *ngFor='let office of offices' [value]="office.id">
                                {{office.name}}
                            </option>
                        </select>
                        <button class="btn btn-outline-secondary" type="button" (click)="save()">Сохранить</button>
                    </div>
                </th>
            </tr>
        </table>
        <br>

        <div class="post-links">
            <button *ngIf="isReceivePdf(post.status)" (click)="genReceivePdf(post.id)" class="btn btn-link">Квитанция о вручении</button>
            <div class="clearfix"></div>
            <button *ngIf="isSendPdf(post.status)" (click)="genPaymentPdf(post.id)" class="btn btn-link">Квитанция об оплате</button>
            <div class="clearfix"></div>
            <button *ngIf="isSendPdf(post.status)" (click)="genSendPdf(post.id)" class="btn btn-link">Квитанция об отправлении</button>
            <div class="clearfix"></div>
            <button *ngIf="isIndexPdf(post.status)" (click)="genIndexPdf(post.id)" class="btn btn-link">Опись отправления</button>
            <div class="clearfix"></div>
            <div *ngIf="post.postalServiceAlias == 1" class="dropdown" appDropdown>
                <button class="btn btn-link dropdown-toggle" type="button">
                    Распечатать конверт
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 229, 114)">С65 – 114х229мм.</a></li>
                    <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 229, 162)">С5 – 162х229мм.</a></li>
                    <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 324, 229)">С4 – 229х324мм.</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
