import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
@Pipe({
    name: 'statusPostReverse'
})
export class StatusPostReversePipe implements PipeTransform {

    transform(status: string) {
        switch (status) {
            case "Создано": return "new";
            case "Заполнено": return "waitingPayment";
            case "Оплачено": return "waitingOfficeActions";
            case "Распечатано": return "waitingForCourier";
            case "В доставке": return "waitingDelivery";
            case "Доставлено": return "completed";
            case "Отменено": return "canceled";
            case "Оставлено извещение" : return "noticeLeft";
            case "Не доставлено" : return "notSent";
        }
        return status;
    }
}
